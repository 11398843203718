@import '~antd/es/style/themes/default.less';
.login {
  position: relative;

  width: 100%;
  min-width: 1420px;
  min-height: 100vh;
  padding-right: 500px;

  background-color: @body-background;
}

.login-right-block {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 500px;
  height: 100%;
  min-height: 100vh;

  // background: linear-gradient(180deg, rgba(13, 68, 187, 1) 0%, rgba(13, 68, 187, 1) 0%, rgba(22, 140, 246, 1) 100%, rgba(22, 140, 246, 1) 100%);
  background-color: #0038bb;
  > img {
    margin: 28px 0;
  }
}

.login-content {
  position: relative;

  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 80px;
}

.login-content__header {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
  height: 80px;
  padding: 0 48px;

  color: @primary-10;
  > img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  > span {
    font-size: @heading-4-size;

    color: @heading-color;
  }
  > .login-content__header__point {
    display: inline-block;

    width: 4px;
    height: 4px;
    margin: 0 8px;

    border-radius: 50%;
    background-color: @primary-10;
  }
}

.login-content__footer {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
  height: 48px;
  padding: 0 48px;
  > span {
    color: #bbbdc4;
  }
}

.login-content__block {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100%;
}

.login-content__form {
  width: 424px;
  padding: 48px 0 96px 0;
}

.login-content__form__h1 {
  font-size: @heading-2-size;
  font-weight: bold;
  line-height: @line-height-base;

  color: @heading-color;
}

.login-content__form__h2 {
  font-size: @font-size-lg;
  line-height: @line-height-base;

  color: @text-color;
}

.white-space {
  height: 48px;
}

.mobile-block {
  position: relative;

  padding-left: 104px;

  border: 1px solid #dedfe2;
  border-radius: @border-radius-base;
}

.zone-block {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  box-sizing: border-box;
  width: 100px;
  height: 48px;
  padding-right: 24px;
  &::after {
    position: absolute;
    top: 50%;
    right: 0;

    width: 1px;
    height: 24px;
    margin-top: -8px;

    content: '';

    background: @grey-2;
  }
}

.zone-block-arrow {
  position: absolute;
  top: 0;
  right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 100%;

  cursor: pointer;
}

.zone-input {
  font-size: @heading-4-size;

  width: 100%;
  height: 48px;
  padding: 0;

  text-align: center;

  color: @text-color;
  border: 0;
  border-radius: @border-radius-base;
  outline: none;
}

.mobile-input {
  font-size: @heading-4-size;

  width: 100%;
  height: 48px;
  padding: 0 16px;

  color: @text-color;
  border: 0;
  border-radius: @border-radius-base;
  background: @component-background;
}

.input-clear-btn {
  font-size: @font-size-lg;

  position: absolute;
  top: 0;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 48px;

  cursor: pointer;
  user-select: none;
}

.input-notice {
  font-size: @font-size-base;
  line-height: @line-height-base;

  padding: 0 8px;

  color: @error-color;
}

.input-notice-text {
  margin-left: 8px;
}

.verification-block {
  height: 48px;
}

.verification-input {
  position: relative;

  float: left;

  width: 258px;

  border: 1px solid #dedfe2;
  border-radius: 4px;
  > input {
    font-size: @heading-4-size;

    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 0 16px;

    color: @heading-color;
    border: 0;
    border-radius: 16px;
  }
}

.password-block {
  position: relative;

  border: 1px solid @border-color-split;
  border-radius: @border-radius-base;
  > input {
    font-size: @heading-4-size;

    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 0 16px;

    color: @heading-color;
    border: 0;
    border-radius: 16px;
  }
}

.sms-btn {
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;

  width: 136px;
  height: 50px;

  cursor: pointer;
  user-select: none;

  border: 1px solid @border-color-split;
  border-radius: @border-radius-base;
  &.disable {
    background-color: @disabled-bg;
  }
}

.sms-btn-text {
  font-size: @heading-4-size;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: @heading-color;
}

.sms-time-text {
  font-size: @heading-4-size;

  color: @disabled-color;
}

.login-toggle-type {
  font-size: @font-size-lg;

  margin-bottom: 48px;

  user-select: none;

  color: @primary-6;
  > span {
    cursor: pointer;
  }
}

.protocol-block {
  position: relative;

  padding-left: 32px;

  > span {
    font-size: @font-size-lg;

    cursor: pointer;

    color: @text-color-secondary;
  }
}

.protocol-checkbox {
  position: absolute;
  top: 0;
  left: 0;
}

.protocol-link {
  color: @link-color;
}

.btn-line {
  margin-top: 16px;
}

.login-btn {
  font-size: @heading-4-size;

  height: 48px;

  border-radius: @border-radius-base;
}


.zone-tel-search {
  margin-bottom: 8px;
}

.zone-list {
  overflow: auto;

  width: 400px;
  height: 304px;
  li {
    font-size: @font-size-base;
    line-height: @line-height-base;

    cursor: pointer;
  }
}

.zone-tel {
  display: inline-block;

  width: 72px;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;