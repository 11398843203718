@import '~antd/es/style/themes/default.less';
.header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  box-sizing: border-box;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  padding-left: 232px;

  border-bottom: 1px solid @border-color-split;
  background-color: #fff;
  > .header-content {
    position: relative;

    box-sizing: border-box;
    height: 100%;
    padding-right: 224px;
  }
}
@media screen and (max-width:1420px) {
  .header {
    position: absolute;

    padding-left: 24px;
  }
}
.other-block {
  font-size: 0;

  position: absolute;
  top: 0;
  right: 0;

  width: 224px;
  height: 100%;

  text-align: right;
  &::before {
    display: inline-block;

    height: 100%;

    content: '';
    vertical-align: middle;
  }
}
.person {
  font-size: 0;

  display: inline-block;

  cursor: pointer;
  vertical-align: middle;
}
.person__icon {
  font-size: @font-size-base;

  position: relative;

  display: inline-block;
  overflow: hidden;

  width: 24px;
  height: 24px;
  margin-right: 8px;

  vertical-align: middle;

  color: #fff;
  border-radius: 50%;
  background: #d9d9d9;
  > img {
    width: 24px;
    height: 24px;
  }
}
.person__icon__bg {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border: 1px solid;
  border-radius: 50%;
}
.person__name {
  font-size: @font-size-base;

  vertical-align: middle;
}
.out-icon {
  color: @error-color;
}
.bell {
  position: relative;

  display: inline-block;

  box-sizing: border-box;
  width: 34px;
  height: 34px;
  margin-right: 24px;

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  color: @grey-6;
  border: 1px solid @border-color-split;
  border-radius: @border-radius-base;
  background-color: @grey-2;
}
.bell__inner {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.bell__inner__content {
  font-size: @heading-4-size;
  line-height: 32px;
  line-height: 32px;

  display: inline-block;

  width: 32px;
  height: 32px;

  text-align: center;
  text-align: center;
}
.search {
  position: relative;

  display: inline-block;

  width: 190px;
  height: 34px;
  margin-right: 24px;
  padding-right: 36px;

  vertical-align: middle;

  border: 1px solid @border-color-split;
  border-radius: 4px;
  background-color: @grey-2;
}
.search__input {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  > input {
    font-size: 14px;
    line-height: 20px;

    width: 100%;
    height: 100%;
    padding: 0;
    padding-right: 20px;
    padding-left: 5px;

    color: @grey-6;
    border: 0;
    background-color: @grey-2;
  }
}
.search__btn {
  font-size: @heading-4-size;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 100%;

  cursor: pointer;

  color: @grey-6;
  border-left: 1px solid @border-color-split;
}
.search__clear-box {
  position: absolute;
  top: 0;
  right: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 100%;
}
.search__clear-box__btn {
  font-size: @font-size-lg;
  font-weight: bold;

  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  cursor: pointer;

  color: @grey-6;
}

.message-list {
  width: 300px;
}

.message-more {
  text-align: right;
}

.message-line {
  cursor: pointer;
  &:hover {
    background-color: @grey-3;
  }
}

.close-all {
  font-size: @heading-4-size;

  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  margin-right: 16px;

  cursor: pointer;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  &:hover {
    color: @error-color;
  }
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;