@import '~antd/es/style/themes/default.less';
.tab-bar {
  font-size: 0;

  height: 100%;
  &::before {
    display: inline-block;

    height: 100%;

    content: '';
    vertical-align: middle;
  }
}

.tab-bar__tab {
  line-height: 32px;

  display: inline-block;

  height: 32px;
  margin-right: 8px;
  padding-right: 24px;
  padding-left: 16px;

  cursor: pointer;
  vertical-align: middle;

  border-radius: 16px;
  &.static {
    padding-right: 16px;
    &.active,
    &:hover {
      padding-right: 16px;
    }
  }
  &:hover {
    padding-right: 8px;

    color: @primary-color;
    background-color: @grey-3;
  }
  &.active {
    padding-right: 8px;

    color: #fff;
    background-color: @primary-color;
  }
  &:hover,
  &.active {
    .tab-bar__tab__icon {
      display: inline;
    }
  }
  &::before {
    display: inline-block;

    height: 100%;

    content: '';
    vertical-align: middle;
  }
  .is-home {
    font-size: @font-size-base;

    margin-right: 6px;

    vertical-align: middle;
  }
}
.tab-bar__tab__title {
  font-size: @font-size-base;

  vertical-align: middle;
}
.tab-bar__tab__icon {
  font-size: @font-size-sm;

  display: none;

  margin-left: 4px;

  vertical-align: middle;
}
.tab-bar__more-btn {
  position: relative;

  display: inline-block;

  width: 24px;
  height: 24px;

  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  color: @grey-6;
  border: 1px solid @border-color-split;
  border-radius: @border-radius-base;
  background-color: @grey-2;
  &::before {
    display: inline-block;

    height: 100%;

    content: '';
    vertical-align: middle;
  }
  &.hasCurrentPage {
    color: #fff;
    background-color: @primary-color;
  }
  &.active {
    .tab-bar__more-btn__inner {
      transform: rotate(-180deg);
    }
  }
}
.tab-bar__more-btn__inner {
  font-size: @font-size-base;

  display: inline-block;

  transition: all .2s;
  vertical-align: middle;
}
.hide-tabs {
  font-size: @font-size-base;

  width: 200px;
}
.hide-tabs {
  li {
    line-height: 32px;

    position: relative;

    box-sizing: border-box;
    height: 32px;
    padding-right: 32px;

    cursor: pointer;
    &.active {
      color: #fff;
      background: @primary-color;
      .hide-tab__close-btn {
        color: #fff;
      }
    }
  }
}
.hide-tab__title {
  overflow: hidden;

  padding-left: 4px;

  white-space: nowrap;
  text-overflow: ellipsis;
}
.hide-tab__close-btn {
  line-height: 32px;

  position: absolute;
  top: 0;
  right: 0;

  width: 32px;
  height: 32px;

  text-align: center;

  color: @grey-6;
  &:hover {
    color: @error-color;
  }
}

.admin-other-page-box {
  .ant-popover-inner-content {
    overflow: auto;

    max-height: 200px;
  }
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;