@import '~antd/es/style/themes/default.less';
.sider {
  position: relative;

  box-sizing: border-box;
  height: 100%;
  padding-top: 64px;
}
.sider__header {
  font-size: 0;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 64px;
  padding: 0 8px;

  background-color: @primary-7;
  &::before {
    display: inline-block;

    height: 100%;

    content: '';
    vertical-align: middle;
  }
  img {
    width: 40px;

    vertical-align: middle;
  }
  h1 {
    font-size: @font-size-lg;

    display: inline-block;

    margin: 0 0 0 16px;

    vertical-align: middle;

    color: #fff;
  }
}
.sider__content {
  height: 100%;

  color: #fff;
  background-color: #0039bf;
}
.menu {
  -webkit-user-select: none;
  > li {
    overflow: hidden;

    cursor: pointer;
    &:hover {
      .menu__firstMenu {
        color: #fff;
      }
    }
    &.active {
      .menu__firstMenu {
        color: rgb(255, 202, 102);
      }
      .menu__direction-icon {
        transition: all .5s;
        transform: rotate(-180deg);
      }
    }
  }
}
.menu__firstMenu {
  font-size: @font-size-base;
  line-height: 56px;

  position: relative;
  // height: 54px;

  padding: 0 40px 0 48px;

  color: rgba(255, 255, 255, .6);
  background-color: @primary-color;
  &.no-icon {
    .firstmenu__title {
      margin-left: -28px;
    }
  }
}
.menu__icon {
  font-size: @font-size-lg;

  position: absolute;
  top: 0;
  left: 0;

  width: 68px;
  height: 48px;
  padding-left: 18px;
}
.menu__direction {
  font-size: @font-size-base;

  position: absolute;
  top: 0;
  right: 8px;

  height: 56px;
  padding-right: 24px;
}
.sub-menu {
  > li {
    font-size: @font-size-base;
    line-height: 28px;
    padding: 8px 0;
    // padding-left: 68px;

    color: rgba(255, 255, 255, .6);
    &:hover {
      color: #fff;
    }
    &.active {
      color: rgb(255, 202, 102);
    }
  }
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;